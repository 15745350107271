@import "./../../assets/scss/vars/colors";
@import "./../../assets/scss/vars/fonts";

.product-filter {
    .rn-select p {
        padding-bottom: 5px;
    }

    .filter-top {
        border-bottom: 2px solid $approvals-darkorange;
    }

    .MuiTabs-indicator {
        display: none !important
    }

    .product-wind-select {
        border-radius: 0px 4px 4px 0px !important;
    }

    .product-select {
        width: 263px !important;
    }

}

.product-ui {

    // handling for attribute overlap on smaller screen sizes
    @media screen and (max-width: 1210px) and (min-width: 766px) {
        .product-filters {
            max-width: 40% !important;
            flex: 0 0 40% !important;
            min-width: 270px;
        }

        .product-outlet {
            max-width: 60% !important;
            flex: 0 0 60% !important;
        }
    }
}