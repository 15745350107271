* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

a:focus {
  outline: none;
}

#root {
  height: 100%;
}

.flex-grow {
  flex-grow: 1 !important;
}

.navigation {
  background-color: white;
  color: navy;
  font-weight: bold;
  padding-left: 10px;
}

.section {
  padding: 30px 10px 10px 10px;
  min-height: 450px;
  background-color: white;
}

.back {
  margin-top: 30px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: navy;
  margin-bottom: 15px;
}

.property-item {
  font-size: 14px;
  font-weight: bold;
  color: navy;
  margin-bottom: 3px;
}

.section .grid th {
  padding: 3px 8px 3px 8px;
  background-color: grey;
  color: white;
}

.section .table th {
  padding: 3px 8px 3px 8px;
}

.section .grid td {
  padding: 4px 8px 4px 8px;
  border-bottom: 1px solid grey;
}

.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.subtitle {
  font-size: 20px;
  font-weight: bold;
  color: navy;
  margin-top: 10px;
  margin-bottom: 6px;
}

.layer-name {
  background-color: #dfdfef;
  border-color: #dfdfef;
  font-size: 12px;
  font-weight: 900;
  margin-bottom: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
}

.loading {
  height: 500px;
  color: navy;
  font-size: 20px;
  font-weight: bold;
  padding-left: 200px;
  padding-top: 200px;
}

.notvisible {
  display: none;
}
@media print {
  header.screenheader{
      display: none;
    }
}