@import "./../../assets/scss/vars/colors";
@import "./../../assets/scss/vars/fonts";

.detail-section {
    .detail-row:nth-of-type(even) {
        background-color: $background-grey;
        padding: 0px 0 !important;
        height: 30px !important;

    }

    .detail-row:nth-of-type(odd) {
        padding: 0px 0 !important;
        height: 30px !important;



    }


}

.summray-section {
    .detail-row:nth-of-type(even) {
        background-color: $background-grey;


    }

    .detail-row:nth-of-type(odd) {
        padding: 0px 0 !important;
    }

}

.system-row {
    margin-left: -20px;
    margin-right: -20px;
    height: fit-content !important;
    padding: 1px 0;

}

.comment-icon {
    margin-top: -3px;
    font-size: 1.8rem;
}

.details-securement {
    padding: 8px !important;
    background-color: $approvals-steel-200;


}

.assembly-summary {

    .table-wrapper,
    .content-wrapper {
        margin-bottom: 10px;
    }

    @media screen and (max-width: 1150px) {

        .table-wrapper,
        .content-wrapper {
            max-width: 100% !important;
            flex: 0 0 100% !important;
        }
    }

    @media screen and (max-width: 1920px) {

        .table-wrapper {
            max-width: 66.66% !important;
            flex: 0 0 66.66% !important;
        }

        .content-wrapper {
            max-width: 33.33% !important;
            flex: 0 0 33.33% !important;
        }
    }
}

.lock-message {
    background: $lock-message-bg !important;
    margin-top: 1rem;
    color: $white-color !important;
    font-weight: 500 !important;
    font-size: 1.25rem !important;
    border-radius: 8px !important;
  }