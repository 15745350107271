#QuickSearch .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.assemblySearch .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #000 !important;
}

#QuickSearch .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #999999 !important;
}

#QuickSearch .mdc-text-field.mdc-text-field--label-floating:not(.mdc-text-field--disabled) .MuiTypography-root {
  display: none;
}

.mdc-list-item.mdc-list-item--incompatible {
  background-color: rgb(100, 100, 100);
  pointer-events: none;
}