@import "./../../../assets/scss/vars/colors";
@import "./../../../assets/scss/vars/fonts";

.detail-section {
  .detail-row:nth-of-type(even) {
    background-color: $approvals-stone-100;
    padding: 0px 0 !important;
    height: 30px !important;

  }

  .detail-row:nth-of-type(odd) {
    padding: 0px 0 !important;
    height: 30px !important;



  }


}

.bold-text {
  // color: maroon;
  font-weight: bold;
}

.summray-section {
  .detail-row:nth-of-type(even) {
    background-color: $approvals-stone-100;


  }

  .detail-row:nth-of-type(odd) {
    padding: 0px 0 !important;
  }

}

.system-row {
  margin-left: -20px;
  margin-right: -20px;
  height: fit-content !important;
  padding: 1px 0;

}

.comment-icon {
  margin-top: -3px;
  font-size: 1.8rem;
}

.details-securement {
  padding: 8px !important;
  background-color: $approvals-steel-200;


}

.assembly-summary {

  .table-wrapper,
  .content-wrapper {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1150px) {

    .table-wrapper,
    .content-wrapper {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }
  }

  @media screen and (max-width: 1920px) {

    .table-wrapper {
      max-width: 66.66% !important;
      flex: 0 0 66.66% !important;
    }

    .content-wrapper {
      max-width: 33.33% !important;
      flex: 0 0 33.33% !important;
    }
  }

  @media print {
    table {
      page-break-before: avoid;
      page-break-inside: auto
    }

    tr {
      page-break-inside: avoid;
      page-break-after: auto
    }

    .pagedisplay {

      page-break-after: auto;
      page-break-before: avoid;
    }

    div.previewNotes {
      page-break-before: auto;
    }

    div.page-break {
      display: block;
      page-break-after: always;
    }

    .printPreview {
      display: block;
    }

    thead {
      display: table-header-group;
    }

    .printDetailInfo {
      display: block;
    }

    a:link {
      border-bottom: none !important;
      text-decoration: none !important;
    }

    .card-body {
      margin-top: 3px;
    }

    a[href]:after {
      content: none !important;
    }

    div.footer {
      display: none;
    }

    div.printfooter {
      display: none;
    }

    div.printbutton {
      display: none;
    }

    .printAdditionalInfo {
      display: none;
    }

    .cataddinfo {
      display: none;
    }

    .navbar {
      display: none;
    }

    .copyright {
      display: none;
    }

    @page {
      size: A4 landscape;
    }


    html,
    body {
      width: 100%;
      height: auto;
      margin-top: 0.4em;
      padding: 0;
    }

    /* Print progressive page numbers */
    .page-number:before {
      /* counter-increment: page; */
      content: "Page: " counter(page);
    }
  }
}