.confirmButton {
  background-color: #722750 !important;
  margin-top: 2rem !important;
}

.confirmButtonDisabled {
  background-color: lightgray !important;
  margin-top: 2rem !important;
}

.backButton {
  color: #722750 !important;
  border-color: #722750 !important;
}

.dm-buttons {
  width: 28% !important;
  margin-left: 1.3rem !important;
  color: #026a76;
  border: 2px solid #026a76;
}

.dm-buttons:hover {
  color: #fff;
  background-color: #026a76;
}

.selected-buttons {
  width: 28% !important;
  margin-left: 1.3rem !important;
  color: #fff;
  background-color: #026a76;
}

.selected-buttons:hover {
  width: 28% !important;
  margin-left: 1.3rem !important;
  color: #fff;
  background-color: #026a76;
}