@import "./../../../assets/scss/vars/colors";
@import "./../../../assets/scss/vars/fonts";

.rn-tooltip {
    .fa-circle-info {

        //color: $tooltip-default;
        &:hover {
            color: $tooltip-active;
        }

        &:focus-within {
            color: $tooltip-active;
        }
    }

    .configit-tip {
        margin: 0.5rem !important;
    }


}

.tooltip-icon {
    font-size: 20px;
}

// .configit-tip:first-of-type {
//     // margin-left: .5rem !important;
//     // margin-right: .5rem !important;
//     // margin-bottom: .5rem !important;
//     margin-top: 20px !important;
// }