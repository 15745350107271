@import "./vars/colors";
@import "./vars/fonts";

.rnfooter {

    /* Top footer section, FM Approvals Links */
    .footer {
        background: $text-secondary;
        color: white;

        .footer-heading {
            color: white;
            font-family: $font-regular;
            font-weight: normal;
            font-size: 20px;
        }

    }

    /* Middle Footer Section, contact FM Approvals + social media buttons */
    .footer-middle {
        background: $approvals-steel-900;

        .footer-middle-right {
            justify-content: end;

        }

        .footer-middle-text {
            font-family: $font-regular;
            padding: 8px;
            font-weight: normal;
            font-style: normal;
            font-size: 16px;
            text-align: left;
            line-height: normal;
            color: $white-color;
            transition: color .2s;
        }
    }


    /* FontAwesome Styling */
    /* Note: for the branding icons, there is some background bleed if the background is set to a different color.
    The social media icons are layered with a white square icon underneath to simulate a white background */
    .fa-circle-chevron-right {
        display: inline-block;
        border-radius: 50%;
        height: 28px;
        width: 28px;
        color: $approvals-stone-100;
        background-color: $circle-background;
        cursor: pointer;
    }

    .fa-linkedin {
        display: inline-block;
        color: $white-color;
        background-color: transparent;
        cursor: pointer;
    }

    .fa-youtube {
        display: inline-block;
        background-color: transparent;
        color: $white-color;
        cursor: pointer;
    }

    /* End FontAwesome Styling */

    .contact-section {
        p {
            font-family: $font-regular;
            text-align: right;
            padding-right: 5px;
        }

        div {
            text-align: left;
            padding-right: 5px;
            line-height: normal;

            li {
                float: right;
                padding: 3px;
            }
        }
    }

    /* End Middle Footer Section */

    /* Bottom Footer Section, Legal and Copyright */
    .footer-bottom {
        background: $approvals-steel-900;
    }

    .footer-bottom div ul li a {
        color: $white-color;
        font-family: $font-regular;
    }

    .footer-bottom-wrapper {
        display: flex;
        align-items: center;
    }

    .footer-bottom-links {
        text-align: left;
    }

    .copyright-text {
        text-align: right;
        font-size: 13px;
        color: $white-color;
        font-family: $font-regular;
    }

    /* End Bottom Footer Section */

    /* Shared styling between areas */
    .links {
        ul {
            list-style-type: none;
        }

        li {
            padding: 10px 0;
            list-style-type: none;
        }

        li a {
            font-family: $font-regular;
            font-weight: normal;
            font-style: normal;
            font-size: 16px;
            text-align: left;
            line-height: 12px;
            color: $white-color;
            transition: color .2s;

            h1 {
                color: $approvals-footer-heading;
            }

            h1:hover {
                text-decoration: none;
                color: $approvals-warm-grey-3;
            }
        }

        li a:hover {
            text-decoration: none;
            color: $approvals-warm-grey-3;
        }

    }

    .section-wrapper {
        display: flex;
        align-items: center;
    }


    .printfooter {
        background: $approvals-steel-600;
    }

    /* End shared styling section */

    /* Media Queries for printing and custom breakpoints when Bootstrap ones are not sufficient */
    @media screen {
        .printfooter {
            display: block;
        }
    }

    @media screen and (max-width: 575px) {

        .copyright-text {
            text-align: left;
            margin-left: 1.5rem;
        }

        .footer-middle-right {
            justify-content: start !important;
        }
    }

    @media print {
        .printfooter {
            display: none;
        }

        .contact-section {
            display: none;
        }
    }

    @media screen and (max-width: 1000px) {
        .feedback-wrapper {
            flex-wrap: wrap;
            display: flex !important;
        }

    }

}