.alert-message-rndm {
  margin-top: 1rem !important;
  border-radius: 1rem !important;
  background-color: #37AEBC !important;
  font-size: 14px !important;
  color: #fff !important;
}

.anchor-tag {
  text-decoration: underline !important;
}

.alert-message-rndm .MuiAlert-icon {
    background-color: #fff !important;
    border-radius: 1rem !important;
    height: 2rem !important;
    padding: 2px !important;
    margin-top: 0.5rem !important;
    width: 2rem !important;
    color: #37AEBC !important;
}