@import "./../../../assets/scss/vars/colors";
@import "./../../../assets/scss/vars/fonts";

.rntextonlycomponent {

    .textonly-btn {
        font-weight: 600;
        font-family: $font-regular;
        font-size: 14px;
        color: $action-blue;
        padding: 3px 5px 1px 5px;
        border-bottom: 2px solid transparent;

        &:active {
            color: $btn-text-active
        }

        &:focus {
            outline: none;
            box-shadow: none !important;

            .icon-wrap {
                outline: 2px solid $action-blue !important;
                outline-offset: 1px;
                box-shadow: none !important;
            }
        }

        &:hover {
            .text {
                text-decoration: underline;
            }

            // 
            //border-bottom: 2px solid $action-blue;

        }

        &:disabled {
            color: $btn-text-disabled;
            text-decoration: none;
        }

        .textonly-icon {
            color: $action-blue;
            //     margin-right: 5px;
            height: 20px;
            width: 20px;
        }

        .disabled-icon {
            color: $btn-text-disabled;
            //     margin-right: 5px;
            height: 20px;
            width: 20px;
        }
    }
}