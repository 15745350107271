.fa-table-icon {
    // top: 6px;
    // right: 10px;
    // position: absolute;
    margin-left: 6px;
    margin-right: 4px;
}
.unsorted .fa-table-icon {
    color: #9EA5AE
}
.bootstrap-table .fixed-table-container .table td, .bootstrap-table .fixed-table-container .table th {
    border: none;
}
