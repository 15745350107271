@import "./../../assets/scss/vars/colors";

.product-results {
    .product-count {
        width: 50px;
        text-align: center;
        padding: 0.3rem 0rem;
    }

    .product-pill {
        font-size: .9rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        padding: .3rem .6rem;
        display: inline-block;
        margin-bottom: .2rem;
        border-radius: 10px;
        background-color: $background-grey;
    }

    .product-pill:hover {
        background-color: #DEE0E2;
        cursor: pointer;
    }

    .product-pill:focus {
        background-color: #BDC2C6;
    }

    .sub-row {
        padding-left: 60px !important;
    }

    .chevron {
        font-size: 16px;
        text-align: center;
    }

}