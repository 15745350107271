@import "./../../assets/scss/vars/colors";
@import "./../../assets/scss/vars/fonts";

.rnfilter {

    .MuiTabs-indicator {
        background-color: $approvals-steel-600;
    }

    .filter-top {
        border-bottom: 2px solid $approvals-concrete-200;
    }

    .assembly-props {
        .form-group {
            margin-bottom: 3rem;
        }

        .form-group:nth-of-type(3),
        .form-group:nth-of-type(9),
        .form-group:nth-of-type(12) {
            margin-bottom: 1rem !important;
        }

        .form-group:nth-of-type(10) {
            margin-bottom: 0rem !important;
        }

        .form-group:nth-of-type(1) .rn-tooltip,
        .form-group:nth-of-type(4) .rn-tooltip,
        .form-group:nth-of-type(11) .rn-tooltip,
        .form-group:nth-of-type(13) .rn-tooltip {
            margin-top: 2rem !important
        }
    }

    .sticky-div {
        position: sticky;
        top: 0px;
        background: $background-sidebar;
        // sticky button would hide behind siblings if z-index not modified
        z-index: 1;
    }

    #QuickSearch {
        width: 66%;
    }
}