@import "./../../../assets/scss/vars/colors";

.rn-dialog {
    .bg-dialog {
        background-color: $approvals-stone-100;
        border-radius: 6px 6px 0px 0px;
    }

    .dialog-close {
        border: none;
        border-radius: 4px;

        &:hover {
            .fa-xmark {
                color: $approvals-steel-400;
            }
        }

        &:focus {
            outline: none;

            .fa-xmark {
                outline: 1px solid $approvals-steel-700;
                border-radius: 4px;
            }
        }
    }

    .dialog-container {
        border-radius: 6px;
    }

    .fa-xmark {
        color: $approvals-steel-700;
        vertical-align: middle;
    }

    .mdc-dialog__surface {
        overflow: visible;
    }

    .mdc-dialog .accordion .card .card-header {
        color: #000;
        font-weight: 600;
        text-decoration: none;
        cursor: pointer;
    }

    .mdc-dialog .accordion .card .card-header.disabled {
        color: #fff;
        background-color: rgb(100, 100, 100) !important;
        font-weight: 600;
        text-decoration: none;
        cursor: default;
    }

    .mdc-dialog .accordion .card .card-header i {
        color: #de288c !important;
    }

    .mdc-dialog .accordion .card .card-header.disabled i {
        color: #fff !important;
    }


}

#DialogAssembly .mdc-dialog__surface {
    width: 100%;
    max-width: 835px;
}

#DialogConfigure .mdc-dialog__surface {
    max-width: 100%;
    width: 480px;
}

#DialogLayers .mdc-dialog__surface {
    max-width: 700px;
    max-height: 640px !important;
    width: 560px;
}