@import "./../../assets/scss/vars/colors";
@import "./../../assets/scss/vars/fonts";

.landing {
    .iconcard {
        .material-symbols-outlined {
            color: $approvals-darkorange;
            font-size: 72px;
        }
    }

    // media query to handle icon card sizing issues at certain screen width
    @media screen and (max-width: 1182px) and (min-width: 992px) {
        .iconcard {
            max-width: 50% !important;
            flex: 0 0 50% !important;
        }
    }
}