@import "./../../assets/scss/vars/colors";

.rn-rooflayer {

    // TODO: !important tags pre-UAT are tech debt- need more time to see what disabling important tag in roofnav.css would effect
    .layer-display-name {
        padding: 2.2px 5px;
        border: 1px solid grey;
        background-color: white;
        border-radius: 4px;
        width: 60%;
        height: 27px;
        display: flex;
        align-items: center;
    }

    .fa-plus-circle {
        color: #722750 !important;
    }

    .form-group .fa-plus {
        font-size: 1.5em !important;

    }

    .pencil-icon {
        color: #fff !important;
        background-color: $approvals-steel-600;
        border: 2px solid $approvals-steel-600;
        cursor: pointer;
        font-size: 20px;
        border-radius: 4px;
    }

    a .close-icon {
        border: 2px solid $approvals-steel-600;
        color: $approvals-steel-600;
        background-color: #fff;
        border-radius: 4px;
        cursor: pointer;
        font-size: 20px;
    }

    a .disabled {
        border: 2px solid #9DA3A9;
        color: #9DA3A9 !important;
    }

    .add-layer {
        color: #722750 !important;
    }

    .layer-bg {
        background-color: $background-grey;
    }

    .roof-separator {
        height: 85px;
    }
}