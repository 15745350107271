// Approvals Cobalt
$approvals-cobalt-300: #0057CA;
// Approvals Concrete
$approvals-concrete-200: #CCCCCC;
// Approvals Grey
$approvals-grey-400: #9DA3A9;
$approvals-grey-600: #5B6670;

// Approvals Orange
$approvals-darkorange: #C93102;
$approvals-darkorange-light: #C9310238;
$approvals-primary-orange: #FF5100;

// Approvals Steel
$approvals-steel-200: #C1C1C8;
$approvals-steel-400: #828497;
$approvals-steel-500: #63657D;
$approvals-steel-600: #383B54;
$approvals-steel-700: #1C1F3C;
$approvals-steel-900: #080A1A;

// Approvals Stone
$approvals-stone-100: #F8F7F4;
// Approvals Warm Grey
$approvals-warm-grey: #6e6358;
$approvals-warm-grey-2: #99918a;
$approvals-warm-grey-3: #b6b1ab;
$approvals-warm-grey-4: #e2e0de;



// Backgrounds
$background-dialog: #EDEBEA;
$background-grey: #F8F7F4;
$background-footer: #5a6167;
$background-sidebar: #F9FAFB;

//Buttons colors
$btn-disabled: #DEE0E2;
$btn-green-light: #a0ce4e;
$btn-green: #92be43;
$btn-hover: #511736;
$btn-hover-cancel: #4a1331;
$btn-red-active: #c34e30;
$btn-red: #bc4526;
//Secondary Button Colors
$btn-secondary-disabled: #9DA3A9;
$btn-secondary-disabled-bg: #EFF0F1;
$btn-secondary-focus: #E3D4DC;
$btn-secondary-hover: #D5B6C6;
$btn-text-disabled: #BDC2C6;
$btn-text-active: #1b55a7;

// Divider
$divider-grey: #C4C4C4;

// Footer
$approvals-footer-heading: #9e9e9e;

//icons
// this is placeholder
$approvals-icon-hover: $approvals-steel-400;

// Links

// Misc Approvals colors
$action-blue: #2a6bc6;
$approvals-green: #cbc995;
$approvals-teal: #17a3b5;
$approvals-error: #DC2626;
$white-color: #ffffff;
$circle-background: #32373A;


// Text colors
$text-primary: #111827;
$text-secondary: #444444;
$text-table-data: #40474e;
$text-white: #F8F7F4;
// Tooltips
$tooltip-default: #080A1A;
$tooltip-active: #2E3338;

$lock-message-bg: #8a6c1d;