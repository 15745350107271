@import "./../../../assets/scss/vars/colors";
@import "./../../../assets/scss/vars/fonts";

.rn-select {
    option {
        font-size: 14px;
        font-weight: 400;
        font-family: 'Inter';
    }

    option:hover {
        background-color: $approvals-steel-700 !important;
    }

    .rn-list-item:disabled,
    .rn-select-default:disabled {
        background-color: #FFFFFF;
        color: $btn-text-disabled;
    }

    .rn-item-selected {
        background-color: $approvals-steel-700 !important;
        color: white;
    }

    .assembly-select {
        padding: 5px;
        background-color: #fff;
    }

    .as-1 {
        border-radius: 0px 4px 4px 0px;
        border-left: 0 !important;
        padding-left: 5px;
    }

    .as-2 {
        border-radius: 4px;
    }

    .rn-error {
        color: $approvals-error;
    }
}