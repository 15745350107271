@import "./../../assets/scss/vars/colors";
@import "./../../assets/scss/vars/fonts";

.rn-contractor-package {
  padding-left: 10px;

  .invalid_message {
    font-size: 2rem;
    font-weight: bold;
    color: red;
    padding-left: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 500px;
  }

  .section-title {
    font-size: 1.3rem;
    font-weight: bold;
    color: white;
    background-color: $approvals-steel-500;
    padding-left: 0.5rem;
    width: 70%;
    print-color-adjust: exact;
  }

  .section-space {
    margin-bottom: 2rem;
  }

  .section-large-space {
    margin-bottom: 6rem;
  }

  .block-space {
    margin-bottom: 1rem;
  }

  .sub-section-title {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .layer-title {
    color: white;
    background-color: $approvals-steel-500;
    font-weight: bold;
    padding-left: 0.5rem;
    width: 70%;
    print-color-adjust: exact;
  }

  .self-securement-title {
    background-color: $approvals-steel-200;
    font-weight: bold;
    padding-left: 0.5rem;
    width: 50%;
    color: $approvals-steel-700;
    print-color-adjust: exact;
  }

  .label-text {
    font-weight: bold;
  }

  .trade-name-text {
    font-weight: bold;
    color: black;
  }

  .comments-label-text {
    width: 30vw;
    margin: auto;
    text-align: justify;
    word-break: break-word;
    white-space: pre-line;
    overflow-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .layer-Comment-line {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .copywrite {
    color: $approvals-steel-700;
    font-size: 10px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 6px;
    print-color-adjust: exact;
  }

  .header-text {
    font-weight: bold;
    font-size: 1.5rem;
  }

  .line-signature-role {
    width: 70%;
    height: 0;
    border: 1px solid black;
    margin-left: 5px;
    margin-top: 1rem;
    display:inline-block;
  }

  .line-signature-title {
    width: 35%;
    height: 0;
    border: 1px solid black;
    margin-left: 5px;
    margin-top: 1rem;
    margin-right: 10px;
    display:inline-block;
  }

  .line-signature-date {
    width: 45%;
    height: 0;
    border: 1px solid black;
    margin-left: 5px;
    margin-top: 1rem;
    display:inline-block;
  }

  .label-signature-role {
    font-weight: bold;
    width: 235px;
    display: inline-block;
  }

  .signature-horizontal-space {
    width: 20px;
    display: inline-block;
  }

  .signature-section-space {
    margin-bottom: 3rem;
  }

  .table-heading-text {
    background-color: white !important;
    color: black !important;
    font-weight: bold;
  }

  .table-container {
    width: 70%;
  }

  .signature-container {
    width: 70%;
    margin-top: 20px;
  }

  .table-label-text {
    font-weight: bold;
    font-size: 12.75px !important;
    margin-right: 5px;
  }

  .table-text {
    font-size: 12.75px !important;
  }

  .line-separator {
    width: 70%;
    height: 0;
    border: 1px solid #dee2e6;
    margin-left: 5px;
    margin-top: 0rem;
    display:inline-block;
  }

  .contact-role {
    font-size: 12.75px !important;
    color: white;
    background-color: $approvals-steel-500;
    font-weight: bold;
    padding-left: 0.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-right: 1rem;
    print-color-adjust: exact;
  }

  .table-container tbody, td, tfoot, th, thead, tr {
    border-bottom: 0px solid #dee2e6 !important;
  }

  .address-label-cell {
    vertical-align: top;
  }

  .address-text-cell {
    padding-left: 5px;
  }

  .classifications-properties-group {
    color: black !important;
    font-weight: bold;
  }

  .table-container h {
    background-color: yellow;
  }


  @media screen {
    div#footer {
      display: none;
    }
  }

  @media print {
    table {
      page-break-before: avoid;
      page-break-inside: auto
    }

    tr {
      page-break-inside: avoid;
      page-break-after: auto
    }



    .pagedisplay {

      page-break-after: auto;
      page-break-before: avoid;
      top: 40rem;
    }

    div.printbutton {
      display: none;
    }

    div#footer {

      position: fixed;
      left: 20px;
      bottom: 20px;
      z-index: 1;
      right: 0;
    }

    /* Specific margins for different sides */
    @page {
      // size: A4 landscape;
      // margin: 1cm;
      size: auto;
      margin: 0
    }


    html,
    body {
      width: 100%;
      height: auto;
      margin-top: 0.5em;
      padding: 0;
      page-break-inside: avoid;

      table:last-of-type {
        page-break-after: auto
      }
    }

    .page-number:before {
      /* counter-increment: page; */
      content: "Page: " counter(page);
    }

    .pagebreak { page-break-before: always; }

  }


}