@import "./../../../assets/scss/vars/colors";
@import "./../../../assets/scss/vars/fonts";

.rntextlink {
    .text-link {
        font-size: inherit;
        font-family: $font-regular;
        font-weight: inherit;
        color: $approvals-darkorange;

        &:active {
            text-decoration: underline;
            color: $approvals-darkorange ;
        }

        &:focus {
            outline: 1px solid $approvals-steel-700 !important;
            border-radius: 2px;

        }

        &:hover {
            text-decoration: underline;
        }

        &:visited {
            text-decoration: underline;
            color: $approvals-cobalt-300;
        }
    }
}