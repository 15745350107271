.usage-detail {
    background-color: white;
    color: black;

    td {
        font-size: 14px !important;
        border-bottom: none;
    }

    tr {
        border-bottom: none !important;
    }

    td:nth-of-type(odd) {
        //width: 42%;
        font-weight: 400;
        font-family: 'Inter';
        padding-right: .75rem;
        text-align: end;
    }

    td:nth-of-type(even) {
        //width: 58%;
        font-weight: 700;
        font-family: 'Inter';
    }
}