@import "./../../../assets/scss/vars/colors";
@import "./../../../assets/scss/vars/fonts";

.rn-text-area {
  .rn-error {
    color: $approvals-error;
  }

  .rn-error-textfield {
    border-color: $approvals-error;
  }
}