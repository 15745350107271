@import "./../../assets/scss/vars/colors";
@import "./../../assets/scss/vars/fonts";

.rn-wind-calc {

    // using styled component created some focus issues, so wind rating text field was styled w/css instead of MUI hook
    .MuiOutlinedInput-input {
        padding: .5em !important;
        width: 275px !important;
        font-size: 1.1rem !important;
    }

    & .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $approvals-steel-700 !important;
    }

    .wind-select {
        width: 275px !important;
    }
}

.rn-summary {
    .summary-section {
        .summary-row:nth-of-type(even) {
            background-color: $approvals-stone-100;
            padding: 8px 0;
        }

        .summary-row:nth-of-type(odd) {
            padding: 8px 0;
        }
    }

    .summary-select {
        width: 275px !important;
    }

    .empty-slot {
        width: 275px !important
    }

    @media screen and (max-width: 1200px) {

        .table-wrapper,
        .content-wrapper {
            max-width: 100% !important;
            flex: 0 0 100% !important;
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    @media screen and (min-width: 1500px) {

        .summary-section {
            max-width: 80% !important;
            flex: 0 0 80% !important;
        }
    }
}