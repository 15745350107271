@import "../../assets/scss/vars/colors";
@import "../../assets/scss/vars/fonts";

.button-link {
    color: #722750 !important;
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-size: 18px !important;

}

.button-link:hover {
    background-color: $approvals-icon-hover;
    border-radius: 6px;
    border-color: $approvals-icon-hover;
}

.doc-link {
    color: $approvals-darkorange !important;

}

.doc-link:hover {
    color: $approvals-darkorange-light !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.a-link {
    color: $approvals-darkorange !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.button-top {
    color: rgb(255, 255, 255);
    border: 2px solid $approvals-darkorange;
    margin: 2px 5px;
    padding: 5px 12px;
    border-radius: 20px;
    text-transform: none;
    background-color: $approvals-darkorange;
    cursor: pointer;

}

.paraheading {
    font-weight: 600;
    font-style: italic;
}

.supporheading {
    font-weight: 600;

}

.refmaterial-ui {

    // handling for attribute overlap on smaller screen sizes
    @media screen and (max-width: 1210px) and (min-width: 766px) {}
}

.glossary {

    .contents a {
        color: $approvals-darkorange;
        font-family: 'Inter' !important;
        font-weight: 600 !important;
        line-height: 40px !important;
        font-size: 28px !important;
    }

    .contents {
        font-family: 'Inter';
        font-weight: 600;
        line-height: 40px;
        font-size: 28px;
    }

    .glossary-link {
        color: $approvals-steel-700;
    }

    p {
        font-family: 'Inter';
        font-weight: 600;
        line-height: 20px;
        font-size: 14px;
    }

    b {
        font-family: 'Inter';
        font-weight: 700;
        line-height: 20px;
        font-size: 16px;
    }

    .top-navigate {

        background-color: $approvals-steel-700;
        border-radius: 10px;
        color: #fff;
        cursor: pointer;

    }

    ul {
        padding-left: 10px;
    }

    li {
        margin-left: 10px;
    }


}