@import "./../../assets/scss/vars/colors";
@import "./../../assets/scss/vars/fonts";

.rnpagenotfound {
    height: 75vh;
    justify-content: center;
    display: flex;
    background-color: white;
    // removes issue with header margin and background
    margin-top: -1.5rem;

    .section-wrapper {
        display: flex;
        align-items: start;
        margin: auto;
        justify-content: center;
        width: 500px;

        .left-text {
            color: $approvals-steel-700;
            font-weight: 800;
            font-size: 36px;
            text-align: end;
            line-height: 40px;
            padding-right: 0.25em;
        }

        .divider {
            background: $divider-grey;
            height: 60px;
            width: 1px;
            left: 0;
            position: relative;
            justify-content: flex-start;
            --tw-translate-x: -50%;
            --tw-translate-Y: -50%;
            transform: var(--tw-transform);
            border: none;

        }

        .right-header {
            width: 300px;
            font-weight: 800;
            font-family: $font-regular;
            line-height: 40px;
            font-size: 36px;
        }

        .right-message {
            font-weight: 500;
            font-family: $font-regular;
        }


    }
}