.btn-group .mdc-button--incompatible.mdc-button--raised:not(:disabled), .btn-group .mdc-button--incompatible.mdc-button--unelevated:not(:disabled) {
    background-color:rgb(100, 100, 100);
    pointer-events:none;
}

@media screen and (max-width: 1100px) and (min-width: 768px) {
    .scaled-font {
        font-size: .8rem !important;
    }
}

.buttonSelect:last-of-type {
    border-radius: 0 !important;
}