// TODO: this should be removed once RadioGroup is converted from an MDC compoent to MUI
.rn-radio-group {
    .incompatible-field input {
        cursor: default;
    }
    .incompatible-label{

            color: #BDC2C6 !important;

    }
}