@import "./vars/colors";
@import "./vars/fonts";

.rnheader {
    .material-symbols-outlined {
        color: $white-color;
        font-size: 44px !important;
    }


    // Account menu
    .account-button {
        background: $approvals-steel-600;
        border: 2px solid transparent;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        font-family: $font-regular;
        font-weight: 600;
        color: $approvals-steel-600;
        padding: 2px 10px 2px 10px;
        font-size: 14px;
    }

    .account-button:hover {
        background-color: $approvals-icon-hover;
        border-radius: 6px;
        border-color: $approvals-icon-hover;
    }

    .account-button:focus {
        background-color: $approvals-darkorange;
        box-shadow: none;
        outline: 2px solid $white-color !important;

        .dropdown-caret::after {
            /* unicode for fontawesome chevron-down */
            content: "\f078";
            color: $text-secondary;
        }
    }

    // Help menu
    .help-button {
        background: $approvals-steel-600;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        font-family: $font-regular;
        font-weight: 600;
        color: $approvals-stone-100;
        padding: 2px 10px 2px 10px;
        font-size: 14px;
    }

    .help-button:hover {
        background-color: $approvals-icon-hover;
        //border-radius: 6px;
        //border-color: $approvals-icon-hover;
    }

    .help-button:focus {
        background-color: $approvals-darkorange;
        box-shadow: none;
        outline: 2px solid $white-color !important;

        .dropdown-caret::after {
            /* unicode for fontawesome chevron-down */
            content: "\f078";
            color: $text-secondary;
        }
    }

    .fa-user .fa-2x {
        font-size: 1.75em;
    }

    .account-button-dropdown {
        display: block;
        width: 100%;
        padding: .75rem 1.5rem;
        clear: both;
        color: $text-secondary;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        font-family: $font-regular;
        font-weight: 600;
        font-style: normal;
        font-size: 14px;
        background: $approvals-stone-100;

        &:focus {
            .dropdown-text {
                outline: 1px solid $approvals-steel-700 !important;
                outline-offset: 4px;
            }
        }

        &:hover {
            color: $approvals-steel-700;
            text-decoration: underline;
        }

        &:active {
            color: $approvals-steel-700
        }
    }

    .help-button-dropdown {
        display: block;
        width: 100%;
        padding: .75rem 1.5rem;
        clear: both;
        color: $text-secondary;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        font-family: $font-regular;
        font-weight: 600;
        font-style: normal;
        font-size: 14px;
        background: $approvals-stone-100;

        &:focus {
            .dropdown-text {
                outline: 1px solid $approvals-steel-700 !important;
                outline-offset: 4px;
            }
        }

        &:hover {
            color: $approvals-steel-700;
            text-decoration: underline;
        }

        &:active {
            color: $approvals-steel-700
        }
    }

    .dropdown-caret::after {
        content: "\f077";
        color: $text-secondary;
    }

    .dropdown-divider {
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
        color: #D2D2D2;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .MuiFormHelperText-root {
        display: none !important;
    }

    // end account menu

    // icons
    .header-link {
        .header-icon {
            padding: 2px;
        }

        &:focus {
            .header-icon {
                background-color: $approvals-darkorange;
                box-shadow: none;
                outline: 2px solid $white-color !important;
                border-radius: 6px;

            }
        }

        &:hover {
            .header-icon {
                background-color: $approvals-icon-hover;
                border-radius: 6px;
            }
        }
    }

    //end icons

    .search-wrapper {
        cursor: pointer;
    }

    .title-font {
        font-family: 'FM Review Web' !important;
    }

    @media screen and (max-width: 1050px) {
        .fa-3x {
            font-size: 2em !important;
        }

        .fa-2x {
            font-size: 1.5em !important;
        }

        .material-symbols-outlined {
            color: $white-color;
            font-size: 30px !important;
        }

        .MuiDivider-root {
            margin-left: 1rem !important;
            margin-right: 1rem !important;
        }

        .reg-logo {
            display: none;
        }

        .title-font {
            font-size: 30px !important;
        }


    }

    @media screen and (min-width: 1051px) {
        .header-hamburger {
            display: none;
        }

        .stacked-logo {
            display: none;
        }

    }




}

.border-bar {
    height: 10px;
    background-color: $approvals-primary-orange;
}