
.confirmButton {
  background-color: #722750 !important;
  margin-top: 2rem !important;
}

.backButton {
  color: #722750 !important;
  border-color: #722750 !important;
}

.saveAsField div input {
  height: 0 !important;
}

.saveAsLabel {
  margin-bottom: -1rem !important;
  color: gray !important;
}

.light-gray {
  background-color: lightgray !important;
  margin-top: 2rem !important;
}

.error-message {
  color: red;
  margin-top: -1rem;
}

.fs-16 {
  font-size: 16px;
}