/* .main-content .accordion.accordion-dark .card .card-header[aria-expanded="false"], .main-content .accordion.accordion-dark .card .card-header:hover {
    background: #9D9DB6  !important;
}

.main-content .accordion.accordion-dark .card .card-header[aria-expanded="true"], .main-content .accordion.accordion-dark .card .card-header:hover {
    background: #B1B1CA !important;
} */

.fa-angle-down, .fa-angle-up {
    font-size: 20px;
}
.accordion-bar {
    height: 1px;
    width: 95%;
    margin-left: 15px;
    margin-right: 15px;
    background-color: #BDC2C6;
}