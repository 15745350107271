@import "./../../assets/scss/vars/colors";

.rn-saved-items {
    .fa-triangle-exclamation {
        transform: translateY(40%);
    }

    .MuiTabs-indicator {
        background-color: $approvals-steel-600;
    }

    @media screen and (min-width: 1000px) {
        .button-section {
            display: flex;
            flex-wrap: wrap;
        }
    }
}

.rn-new-project {
    .MuiOutlinedInput-input {
        padding: .5em !important;
        width: 275px !important;
        font-size: 1.1rem !important;
    }

    .roof-select {
        background-color: white !important;
    }

    .right-section {
        display: flex;
        justify-content: end;
    }

    .project-select {
        width: 289px !important;
        background-color: white !important;
        //   margin-left: 6px !important;
    }

    & .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $approvals-steel-700 !important;
    }

    @media screen and (max-width: 1250px) {
        flex-direction: column-reverse;

        .right-section {
            flex: 0 0 100% !important;
        }
    }


    @media screen and (max-width: 992px) {

        .right-section {
            flex: 0 0 100% !important;
            justify-content: start !important;
        }
    }
}

.contacts {
    .contact-row {
        flex-direction: column;
        display: flex;
        justify-content: space-between;
    }

    @media screen and (max-width: 1100px) {
        .contact-section {
            display: flex;
            max-width: 100% !important;
            flex: 0 0 100% !important;
        }
    }

    @media screen and (min-width: 1101px) {
        .contact-section {
            display: flex;
            max-width: 50% !important;
            flex: 0 0 50% !important;
        }
    }
}