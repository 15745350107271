.ButtonToggle.btn-group .mdc-button--raised.active:not(:disabled) {
    background: #de288c;
}
.ButtonToggle.btn-group .mdc-button--raised.active:not(:disabled) .mdc-button__label {
    color: #fff;
}
.ButtonToggle.btn-group .mdc-button--raised:not(:disabled), .ButtonToggle.btn-group .mdc-button--unelevated:not(:disabled) {
    border-color: #de288c;
}
.ButtonToggle.btn-group .mdc-button .mdc-button__label {
    color: #de288c;
}