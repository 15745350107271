// TODO: remove this scss file and replace w/MUI components in future PBI
.rn-rooflayer .multi-select-box {
    width: 79% !important;

    // width: 63% !important;
    padding-left: 1.5rem !important;

}

.multi-select-box {
    .select-box {
        border: 1px solid #5B6670;
        border-radius: 4px;
    }
}