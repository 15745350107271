@import '../../assets/scss/vars/colors';

.manufacture-form {
    a .fa-xmark {
        padding: 4px 6px;
        border: 2px solid $approvals-steel-700;
        color: $approvals-steel-700 !important;
        background-color: #fff;
        border-radius: 4px;
    }

    .form-control:focus {
        border-color: $approvals-steel-700;
    }

    // Table
    tr:not(first-of-type) {
        padding-left: 1rem;
    }

    tbody {
        border-bottom: none !important;
    }

    th {
        border-top: none !important;
        border-bottom: none !important;
    }

    td {
        border-bottom: none !important;
        min-height: 48px;
    }

    .xmark {
        border-top: none !important;
    }

    input {
        text-overflow: ellipsis;
        padding-left: 1rem;
        width: 263px !important;
    }

    .MuiAutocomplete-inputRoot {
        width: 263px !important;
    }

    #btnTableAdd {
        color: $action-blue;
    }
}

.securement-form {
    .select-box {
        width: 263px !important;
    }

    .form-group:nth-of-type(even) {
        padding-left: 0;
    }

    .select-box {
        border-radius: 4px;
    }
}

.attribute-form {
    .section-wrapper {
        display: 'flex';
        flex-direction: 'column';

        p {
            margin-bottom: 5px !important;
        }
    }


    .select-box {
        border-radius: 4px;
        background-color: #fff;
    }

    .advanced-selector {
        vertical-align: text-bottom;

    }

    .form-group:nth-of-type(even) {
        padding-left: 0;
    }

    td {
        max-width: 263px;
    }

    .prod-operator {
        width: 80px;
        border-radius: 4px 0px 0px 4px;
        border-right: none !important;

    }

    .prod-value {
        width: calc(100% - 150px);

        .select-box {
            border-radius: 0;
        }
    }

    .prod-select-value {
        width: calc(100% - 150px);
        max-width: 263px;

        .select-box {
            border-radius: 0;
        }
    }

    .prod-measure {
        width: 70px;
        border: 1px solid $approvals-grey-600;
        border-left: none;
        border-radius: 0px 4px 4px 0px;
        display: flex;
        align-items: center;
        height: 36px;
        background-color: #fff;
    }
}